<template>
  <div>
    <b-alert variant="secondary" show>
      <b-row cols="12" class="mb-1 alert-heading">
        <b-col class="d-flex align-items-center justify-content-between">
          <b-form-checkbox @change="hideShowFilter"
            ><b>show filters</b></b-form-checkbox
          >
          <v-select
            style="width: 200px"
            @input="getAllInvoice"
            v-model="filters.invoiceStatus"
            :options="invoiceStatusList"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
          />
          <b-form-input
            style="width: 400px"
            @input="getAllInvoice"
            placeholder="Search Invoice(1000,1001)"
            id="fromg"
            v-model="filters.invNumbers"
          />

          <b-dropdown
            right
            size="sm"
            text="Actions"
            variant="gradient-secondary"
            id="dropdown-form"
            ref="dropdown"
          >
            <b-dropdown-form>
              <b-button
                v-if="invoiceList.length > 0"
                block
                size="sm"
                class="w-100"
                variant="gradient-success"
                @click="
                  bankModalShow = !bankModalShow;
                  uploadMessage = null;
                "
              >
                <span class="text-nowrap"> Upload Bank Invoice </span>
              </b-button>

              <b-dropdown-divider></b-dropdown-divider>

              <b-button
                block
                size="sm"
                class="w-100"
                variant="gradient-success"
                v-if="invoiceList.length > 0"
                @click="
                  showReminderModal = !showReminderModal;
                  conReminderMessage = null;
                  isBulkReminder = true;

                  sendReminderData.message =
                    '<h3>Mahnung / Rappel / Reminder </h3><p>Gemäss unserer Buchhaltung sind die folgenden Rechnungen noch nicht beglichen. Sollten die Rechnungen bezahlt sein, bitten wir um Angabe des Zahlungsdatums. Andernfalls erwarten wir Ihre Zahlung oder eine Begründung für den ausstehenden Betrag in den nächsten 7 Tagen.</p><p>Selon notre comptabilité, les factures suivantes ne sont pas encore réglées. Nous vous prions de bien vouloir vous acquitter du montant dû dans un délai de 7 jours.</p><p>According to our accounting department, the following invoices have not yet been paid. If the invoices have been paid, please indicate the date of payment. Otherwise, we expect your payment or justification for the outstanding amount in the next 7 days.</p>';
                "
              >
                <span class="text-nowrap"> Send Consolidate Reminder </span>
              </b-button>
              <b-dropdown-divider></b-dropdown-divider>

              <b-button
                block
                size="sm"
                class="w-100"
                variant="gradient-success"
                @click="exportData"
              >
                <span class="text-nowrap">
                  Export Invoice Payment Summary
                </span>
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-alert>

    <b-row class="m-1" v-if="this.filtersHidden == false">
      <b-col cols="12" md="4">
        <b-form-group label="Licensee">
          <v-select
            multiple
            @input="getAllInvoice"
            :reduce="(label) => label.vendor_id"
            v-model="filters.selectedVendor"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="vendor_list"
            label="company"
            value="vendor_id"
            placeholder="Select Licensee"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Inv Date From">
          <flat-pickr
            @on-change="getAllInvoice"
            v-model="filters.invoiceFromDate"
            placeholder="Select Date"
            class="form-control"
            :config="{ ...$flatPickrConfig }"
          /> </b-form-group
      ></b-col>
      <b-col md="2">
        <b-form-group label="Inv Date Till">
          <flat-pickr
            @on-change="getAllInvoice"
            v-model="filters.invoiceToDate"
            placeholder="Select Date"
            class="form-control"
            :config="{ ...$flatPickrConfig }"
          /> </b-form-group
      ></b-col>
      <b-col md="2">
        <b-form-group label="Report Type">
          <v-select
            :reduce="(report) => report.value"
            v-model="selectedReport"
            placeholder="Report Type"
            label="title"
            :options="optionReport"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3">
        <b-button @click="onExportInvoices" variant="outline-primary"
          >Export</b-button
        >
        <b-button class="ml-1" @click="resetFilters" variant="outline-warning"
          >Reset</b-button
        >
      </b-col>

      <!-- <b-col cols="12" md="3">
          <b-form-group label="Filter By Movies">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :reduce="(label) => label.movie_id"
              @input="getAllInvoice"
              v-model="filters.selectedMovie"
              placeholder="Select Movies"
              label="full_title"
              value="movie_id"
              :options="optionMovies"
            />
          </b-form-group>
        </b-col> -->
    </b-row>

    <b-card no-body>
      <b-table
        bordered
        :tbody-tr-class="rowClass"
        :fields="fields"
        :items="invoiceList"
        empty-text="No  records found"
        show-empty
        responsive
        small
        hover
      >
        <!-- A virtual column -->
        <!-- <template #cell(index)="data">
          {{ data.index + 1 }}
        </template> -->
        <template #head(sr)="data">
          <b-badge v-if="pagination.totalRows" variant="success">{{
            pagination.totalRows
          }}</b-badge>
        </template>
        <template #cell(sr)="data">
          {{
            data.index + 1 + (pagination.currentPage - 1) * pagination.perPage
          }}
          <!-- <div v-if="data.item.invoice_doc" class="text-nowrap">
            <b-dropdown size="sm" text="Invoice">
              <b-dropdown-item-button
                v-for="(item, index) in data.item.invoice_doc.split(',')"
                :key="index"
                @click="
                  $router.push({
                    name: 'PreviewInvoice',
                    params: {
                      revenue_invoice_id: data.item.revenue_invoice_id,
                    },
                    query: { pdf: item },
                  })
                "
              >
                {{
                  item && item.split("--").length > 1
                    ? "View Invoice " + item.split("--")[0]
                    : "View Invoice"
                }}</b-dropdown-item-button
              >
            </b-dropdown>
          </div> -->
        </template>
        <template #cell(invoice_unique_id)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="outline-danger"
              v-if="data.item.invoice_doc"
              size="sm"
              text="Invoice"
            >
              <b-dropdown-item-button
                v-if="index == 0"
                v-for="(item, index) in data.item.invoice_doc.split(',')"
                :key="index"
                @click="
                  $router.push({
                    name: 'PreviewInvoice',
                    params: {
                      revenue_invoice_id: data.item.revenue_invoice_id,
                    },
                    query: { pdf: item },
                  })
                "
              >
                {{
                  item && item.split("--").length > 1
                    ? "View Invoice "
                    : "View Invoice"
                }}</b-dropdown-item-button
              >
              <!-- Payment Details dropdown item -->
              <b-dropdown-item-button
                v-if="data.item.strono_credit_canceled == 0"
                @click="
                  $router.push({
                    name: 'PreviewInvoice',
                    params: {
                      revenue_invoice_id: data.item.revenue_invoice_id,
                    },
                    query: { pdf: item + 1 },
                  })
                "
              >
                Payment Details
              </b-dropdown-item-button>
              <b-dropdown-item-button
                v-if="
                  data.item.strono_credit_canceled == 0 &&
                  !data.item.received_amount
                "
                @click="
                  () => {
                    showModal(
                      'Are you sure want to mark invoice storno.This can not be reversed?',
                      'delete'
                    ).then((value) => {
                      if (value)
                        markInvoiceStorno(data.item.revenue_invoice_id);
                    });
                  }
                "
              >
                Mark Storno/Credit Note
              </b-dropdown-item-button>
            </b-dropdown>

            <span
              v-if="data.item.revenue_id == '0'"
              v-b-tooltip.hover
              title="Manual Invoice"
            >
              <b-badge pill variant="light-warning" class="ml-1">{{
                data.item.revenue_id == "0" ? "M" : ""
              }}</b-badge>
            </span>
            <hr />
            <span> Invoice No : {{ data.item.invoice_unique_id }} </span>
            <hr />
            <span>
              Created on.:
              {{
                data.item.createdAt
                  ? moment(data.item.createdAt).format("DD/MM/YYYY")
                  : ""
              }}
            </span>
          </div>
        </template>
        <template #cell(revenue_id)="data">
          <div v-if="data.item.strono_credit_canceled == 1">
            <h6>
              <b-badge pill variant="light-success"
                >Strono/Credit/Cancelled</b-badge
              >
            </h6>
          </div>

          <div
            v-if="
              data.item.received_amount && data.item.strono_credit_canceled == 0
            "
          >
            <!-- v-if="
                  data.item.received_amount &&
                  parseFloat(data.item.invoice_total) ==
                    data.item.paidAmount.split(',').reduce(function (a, b) {
                      return +parseFloat(a) + +parseFloat(b);
                    })
                " -->

            <div
              v-if="
                data.item.received_amount &&
                parseFloat(data.item.invoice_total) ==
                  parseFloat(data.item.received_amount).toFixed(2)
              "
            >
              <h6>
                <b-badge pill variant="light-success">Full Paid</b-badge>
              </h6>
            </div>
            <!-- v-else-if="
                  parseFloat(data.item.invoice_total) -
                    data.item.paidAmount.split(',').reduce(function (a, b) {
                      return +parseFloat(a) + +parseFloat(b);
                    }) <
                  0
                " -->
            <div
              v-else-if="
                parseFloat(data.item.invoice_total) -
                  parseFloat(data.item.received_amount).toFixed(2) <
                0
              "
            >
              <h6><b-badge pill variant="light-dark">Over Paid</b-badge></h6>
            </div>
            <!-- v-else-if="
                  parseFloat(data.item.invoice_total) -
                    data.item.paidAmount.split(',').reduce(function (a, b) {
                      return +parseFloat(a) + +parseFloat(b);
                    }) >
                  0
                " -->
            <div
              v-else-if="
                parseFloat(data.item.invoice_total).toFixed(2) -
                  parseFloat(data.item.received_amount).toFixed(2) >
                0
              "
            >
              <h6>
                <b-badge pill variant="light-warning">Partial Paid </b-badge>
              </h6>
            </div>
            <div v-else>
              <h6><b-badge pill variant="light-danger">Not Paid</b-badge></h6>
            </div>
          </div>
          <div v-else>
            <h6 v-if="data.item.strono_credit_canceled == 0">
              <b-badge pill variant="light-danger">Not Paid</b-badge>
            </h6>
          </div>
        </template>

        <template #cell(invoice_sub_total)="data">
          <div class="text-nowrap">
            <span>
              Invoice Total :
              <b
                >{{
                  data.item.selectedCurrency
                    ? data.item.selectedCurrency
                    : "CHF"
                }}
                {{ formatCurrency(data.item.invoice_total) }}</b
              >
            </span>
            <hr />
            <span>
              Amount Paid :
              <b>
                <span v-if="data.item.received_amount">
                  {{
                    data.item.selectedCurrency
                      ? data.item.selectedCurrency
                      : "CHF"
                  }}
                  <!-- formatCurrency(
                      data.item.paidAmount.split(",").reduce(function (a, b) {
                        return (
                          +parseFloat(a).toFixed(2) + +parseFloat(b).toFixed(2)
                        );
                      })
                    ) -->
                  {{ formatCurrency(data.item.received_amount) }}
                </span>
                <span v-else
                  >{{
                    data.item.selectedCurrency
                      ? data.item.selectedCurrency
                      : "CHF"
                  }}
                  {{ formatCurrency(0.0) }}
                </span></b
              >
            </span>
            <hr />
            <div v-if="data.item.received_amount">
              Amount Due :
              <b
                v-if="
                  parseFloat(data.item.invoice_total) >
                  parseFloat(data.item.received_amount)
                "
              >
                {{
                  data.item.selectedCurrency
                    ? data.item.selectedCurrency
                    : "CHF"
                }}
                {{
                  formatCurrency(
                    parseFloat(data.item.invoice_total) -
                      parseFloat(data.item.received_amount)
                  )
                }}</b
              >
              <b v-else>
                {{
                  data.item.selectedCurrency
                    ? data.item.selectedCurrency
                    : "CHF"
                }}
                0.0</b
              >
            </div>
            <div v-else>
              Amount Due :
              <b
                >{{
                  data.item.selectedCurrency
                    ? data.item.selectedCurrency
                    : "CHF"
                }}
                {{
                  data.item.strono_credit_canceled == 1
                    ? formatCurrency(0)
                    : formatCurrency(data.item.invoice_total)
                }}</b
              >
            </div>
          </div>
        </template>

        <!-- <template #cell(createdAt)="data">
          {{ data.value ? moment(data.value).format("DD/MM/YYYY") : "" }}
        </template> -->
        <template #cell(bank_invoice_file)="data">
          <div v-if="data.item.invoice_documents">
            <a
              v-for="(item, index) in data.item.invoice_documents.split(',')"
              :key="index"
              @click="downloadBankInv(item)"
              >{{ item }}<br
            /></a>
          </div>
        </template>

        <template #cell(received_amount_date)="data">
          <div v-if="data.item.paidAmountDate">
            <span
              v-for="(item, index) in data.item.paidAmountDate.split(',')"
              :key="index"
              ><b-badge variant="light-info">{{
                moment(item).format("DD/MM/YYYY HH:mm")
              }}</b-badge>
              {{
                data.item.paidAmount
                  ? data.item.paidAmount.split(",")[index]
                  : 0
              }}
              {{
                data.item.selectedCurrency ? data.item.selectedCurrency : "CHF"
              }}
              <br
            /></span>
          </div>
        </template>

        <template #cell(date_for_royalty_report)="data">
          <!-- <div v-if="data.item.reminderSentDate">
            <span>
              {{
                moment(Date.now())
                  .startOf("day")
                  .diff(
                    moment(data.item.reminderSentDate.split(",")[0]).startOf(
                      "day"
                    ),
                    "days"
                  )
              }}</span
            >
          </div> -->

          <div>
            <span>
              {{
                moment(Date.now())
                  .startOf("day")
                  .diff(moment(data.item.createdAt).startOf("day"), "days")
              }}</span
            >
          </div>
        </template>

        <template #cell(reminderSentDate)="data">
          <div
            class="text-nowrap w-100 text-center"
            v-if="data.item.reminderSentDate"
          >
            <span
              class="w-100 text-center"
              v-for="(item, index) in data.item.reminderSentDate
                .split(',')
                .reverse()"
              :key="index"
              @click="
                downloadReminder(
                  data.item.reminderDoc.split(',').reverse()[index]
                )
              "
              ><b
                ><u
                  >R{{ index + 1 }}-
                  {{ moment(item).format("DD/MM/YYYY") }}
                </u></b
              >
              <br />
            </span>
          </div>
          <hr />
          <div
            v-if="
              (!data.item.received_amount ||
                parseFloat(data.item.invoice_total).toFixed(2) >
                  parseFloat(
                    data.item.received_amount ? data.item.received_amount : 0
                  ).toFixed(2)) &&
              data.item.strono_credit_canceled == 0
            "
            @click="onSendReminder(data.item)"
            right
            size="sm"
            variant="link"
            class="text-center w-100"
          >
            <b-badge variant="info" class="text-nowrap">Send Reminder</b-badge>
          </div>
        </template>

        <template #cell(updatedAt)="data">
          <div class="text-nowrap">
            {{
              data.item.updatedAt
                ? moment(data.item.updatedAt).format("DD/MM/YYYY HH:mm")
                : ""
            }}
            <!-- <hr />
            Invoice Type :
            {{ data.item.revenue_id == "0" ? "Manual" : "Other Revenue" }} -->
          </div>
        </template>

        <template #cell(for_royalty_report)="data">
          {{ data.item.for_royalty_report == 1 ? "Yes" : "No" }}
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              type="submit"
              class="mr-1"
              right
              size="sm"
              variant="outline-primary"
              @click="
                $router.push({
                  name: 'PreviewInvoice',
                  params: { revenue_invoice_id: data.item.revenue_invoice_id },
                })
              "
            >
              View Invoice
            </b-button>
          </div>
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                @change="onPageChange"
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>

    <b-modal
      size="lg"
      :no-close-on-backdrop="true"
      no-close-on-esc
      hide-footer
      v-model="bankModalShow"
      modal-class="modal-primary"
      id="modal-center"
      title="Upload Bank Invoice"
      scrollable
    >
      <b-card-text>
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group
              class="mt-3"
              label="Bank Xml"
              label-for="mc-revenue-excel"
            >
              <b-form-file v-model="bankInvoice" id="IANA" accept=".xml" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-alert
          v-if="uploadMessage"
          class="d-flex align-items-center justify-content-center mb-0 mb-md-0 sticky-top"
          variant="dark"
          show
        >
          <div class="alert-body">
            <div>{{ uploadMessage }}</div>
          </div>
        </b-alert>

        <div class="text-center">
          <b-button
            :disabled="!bankInvoice"
            class="mt-1 mb-3"
            variant="primary "
            @click="handleUploadBankInv"
          >
            Submit
          </b-button>
          <hr />
          <h5>
            <b
              >Note : Invoice Payment Status can take upto 1 min to reflect
              after upload</b
            >
          </h5>
          <hr />
          <div>
            <bank-payments />
          </div>

          <!-- <b-table
            bordered
            class="mt-2"
            v-if="insertedData.length > 0"
            :tbody-tr-class="rowClass"
            :fields="fieldsBankInv"
            :items="insertedData"
            empty-text="No  records found"
            show-empty
            responsive
          >
            <template #cell(actions)="data">Updated to Paid </template>
          </b-table>-->
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      :no-close-on-backdrop="true"
      no-close-on-esc
      size="xl"
      v-model="showReminderModal"
      id="modal-prevent-closing"
      title="Payment Reminder"
      @ok="handleOk"
    >
      <b-form class="p-2" @submit.prevent>
        <b-form-group v-slot="{ ariaDescribedby }">
          <div class="d-flex">
            <b-form-radio
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              :value="true"
              v-model="onlyGenerate"
              class="mr-2"
              @input="changeReminderDetails"
              >Only Generate Reminder PDF</b-form-radio
            >
            <b-form-radio
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              v-model="onlyGenerate"
              :value="false"
              @input="changeReminderDetails"
              >Generate and Send Reminder PDF</b-form-radio
            >
          </div>
        </b-form-group>
        <hr />
        <!-- From -->
        <b-row v-if="!onlyGenerate">
          <b-col cols="12" md="6">
            <b-form-group label="From" label-for="from" v-if="!onlyGenerate">
              <b-form-input
                id="from"
                v-model="sendReminderData.from"
                trim
                type="email"
              />
            </b-form-group>
          </b-col>

          <!-- To -->
          <b-col cols="12" md="6">
            <b-form-group label="To" label-for="to" v-if="!onlyGenerate">
              <b-form-input
                id="to"
                v-model="sendReminderData.to"
                trim
                type="email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="isBulkReminder == true">
          <b-col cols="12" md="6">
            <b-form-group label="Licensee">
              <v-select
                @input="getLicensorUnpaidInvoice"
                :reduce="(label) => label.vendor_id"
                v-model="sendReminderData.consolidateReminderVendor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vendor_list"
                label="company"
                value="vendor_id"
                placeholder="Select Licensee"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Invoice Numbers (Not Paid & Partial Paid)">
              <v-select
                :closeOnSelect="false"
                multiple
                v-model="sendReminderData.consolidateInvoiceNo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoiceNumberList"
                label="invoice_unique_id"
                value="revenue_invoice_id"
                placeholder="Invoice Number"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Subject -->
        <b-form-group label="Subject" label-for="subject" v-if="!onlyGenerate">
          <b-form-input id="subject" v-model="sendReminderData.subject" trim />
        </b-form-group>

        <!-- Message -->
        <b-form-group label="Reminder Message" label-for="subject">
          <quill-editor v-model="sendReminderData.message"> </quill-editor>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BTooltip,
  BPagination,
  BModal,
  BFormFile,
  BFormGroup,
  BCardText,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BButtonGroup,
  BFormRadio,
  BDropdownForm,
  BDropdownDivider,
} from "bootstrap-vue";
import {
  GetAllInvoice,
  SendInvoiceReminder,
  GetLicensorInvoiceNumbers,
  InvoiceReports,
  MarkInvoiceStornoCanceled,
} from "@/apiServices/RevenueServies";

import vSelect from "vue-select";
import moment from "moment";
import axios from "axios";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { getAllMovie } from "@/apiServices/MovieServices";
import { getAllVendor } from "@/apiServices/VendorServices";
import { formatCurrency } from "@/utils/helpers";
import qs from "qs";
import flatPickr from "vue-flatpickr-component";
import showModal from "../../../components/ModalConfirmation";
import BankPayments from "./BankPayments.vue";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    BCard,
    BAlert,
    BTable,
    BProgress,
    BBadge,
    vSelect,
    BButton,
    BTooltip,
    BRow,
    BCol,
    BPagination,
    BModal,
    BFormFile,
    BFormGroup,
    BCardText,
    ToastificationContentVue,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BForm,
    BFormInput,
    BFormTextarea,
    quillEditor,
    BFormCheckbox,
    BButtonGroup,
    flatPickr,
    BFormRadio,
    BankPayments,
    BDropdownForm,
    BDropdownDivider,
  },
  data() {
    return {
      sendReminderData: {
        from: "",
        to: "",
        subject: "",
        message: "",
        consolidateReminderVendor: null,
        consolidateInvoiceNo: [],
      },
      onlyGenerate: true,

      optionMovies: [],
      vendor_list: [],
      bankModalShow: false,
      conReminderModalShow: false,
      showReminderModal: false,
      isBulkReminder: false,
      selectedInvoice: null,
      filters: {
        invoiceStatus: "All",
        invNumbers: null,
        selectedMovie: null,
        selectedVendor: null,
        invoiceToDate: null,
        invoiceFromDate: null,
      },
      invoiceStatusList: [
        "All",
        "Full Paid",
        "Partial Paid",
        "Not Paid",
        "strono",
      ],
      BASE_URL: process.env.VUE_APP_BASEURL,
      files_url: process.env.VUE_APP_FILESURL,
      loading: false,
      bankInvoice: null,
      uploadMessage: null,
      conReminderMessage: null,

      moment: moment,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: null,
      },
      perPage: 15,
      totalDue: 0,
      perPageOptions: [25, 50, 100],
      fields: [
        { key: "sr", label: "", class: "text-center" },
        { key: "revenue_id", label: "Status", class: "text-center" },

        // { key: "title", label: "Movie", sortable: true },
        { key: "invoice_unique_id", label: "Invoice", sortable: true },
        { key: "company", label: "Licensee", sortable: true },

        // { key: "status", label: "Payment Status" },

        // { key: "createdAt", label: "Invoice Created Date", sortable: true },

        { key: "invoice_sub_total", label: "Amount ", variant: "info" },
        // {
        //   key: "received_amount_date",
        //   label: "Amount Paid Date",
        //   sortable: true,
        // },
        {
          key: "date_for_royalty_report",
          label: "Days Overdue",
          sortable: true,
        },

        // { key: "bank_payment", label: "Write Off Amount" },

        {
          key: "reminderSentDate",
          label: "REMINDER",
          sortable: true,
        },

        { key: "invoice_reference_number", label: "Inv.Ref No" },

        // {
        //   key: "date_for_royalty_report",
        //   label: "DATE FOR ROYALTY REPORT",
        //   sortable: true,
        // },
        { key: "updatedAt", label: "Last Updated", sortable: true },
        {
          key: "for_royalty_report",
          label: "For Royalty Report",
          sortable: true,
        },
        { key: "bank_invoice_file", label: "Bank Invoice(xml)" },
        // { key: "bank_payment", label: "Payment" },

        // { key: "revenue_id", label: "Invoice Type" },

        // { key: "actions", label: "Actions" },
      ],
      fieldsBankInv: [
        { key: "invoiceNo", label: "Inv.No" },
        { key: "referenceNo", label: "Inv.No" },
        { key: "actions", label: "Actions" },
      ],
      selectedReport: 0,
      optionReport: [
        { title: "Default", value: 0 },
        { title: "Received Statement Report", value: 1 },
        { title: "Account Receivable Report", value: 2 },
      ],
      invoiceList: [],
      insertedData: [],
      invoiceNumberList: [],
      filtersHidden: true,
      tabIndex2: 1,
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  beforeMount() {
    this.loadAllInvoice();
    this.GetAllMovie();
    this.getVendors();
    // this.tabIndex2 = 1  ;
  },
  methods: {
    showModal,
    formatCurrency,
    async markInvoiceStorno(invID) {
      this.invoiceList = [];
      try {
        const response = await MarkInvoiceStornoCanceled({
          invoiceId: invID,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getAllInvoice();
        }
      } catch (err) {
        console.log("Error in MarkInvoiceStornoCanceled ", err);
      }
    },
    resetFilters() {
      this.selectedReport = 0;
      this.filters = {
        invoiceStatus: "All",
        selectedMovie: null,
        invNumbers: null,
        selectedVendor: null,
        invoiceToDate: null,
        invoiceFromDate: null,
      };
      this.pagination = {
        currentPage: 1,
        perPage: 15,
        totalRows: null,
      };
      this.getAllInvoice();
    },
    loadAllInvoice() {
      // let filtersAndPaginationStr = localStorage.getItem(
      //   "revenueinvoice-FiltersAndPagination"
      // );
      // if (filtersAndPaginationStr) {
      //   let obj = JSON.parse(filtersAndPaginationStr);
      //   this.filters = obj.filters;
      //   this.pagination = obj.pagination;
      // }
      this.getAllInvoice();
    },
    changeReminderDetails() {
      this.sendReminderData = this.onlyGenerate
        ? {
            ...this.sendReminderData,
            from: "",
            to: "",
            subject: "",
          }
        : {
            ...this.sendReminderData,
            from: "yusuf.borkar@enpointe.io",
            to: "",
            subject: "",
          };
    },

    onExportInvoices() {
      let url = null;
      let excelName = "";
      let payload = {
        selectedVendor: this.filters.selectedVendor
          ? this.filters.selectedVendor
          : "",
        is_paid:
          this.filters.invoiceStatus == "Full Paid"
            ? "1"
            : this.filters.invoiceStatus == "Partial Paid"
            ? "2"
            : this.filters.invoiceStatus == "Not Paid"
            ? "0"
            : this.filters.invoiceStatus == "strono"
            ? "3"
            : "",
        invNumbers: this.filters.invNumbers,
        invoiceFromDate: this.filters.invoiceFromDate,
        invoiceToDate: this.filters.invoiceToDate,
        selectedReport: this.selectedReport,
      };
      const anchorElement = document.createElement("a");
      document.body.appendChild(anchorElement);
      anchorElement.style.display = "none";

      url =
        this.BASE_URL +
        `/revenue/invoiceReports?is_paid=${payload.is_paid}&selectedVendor=${payload.selectedVendor}&invoiceFromDate=${payload.invoiceFromDate}&invoiceToDate=${payload.invoiceToDate}&selectedReport=${this.selectedReport}`;

      fetch(url, {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          if (this.selectedReport == 1) {
            excelName = "Recieved_Statement_Report.xlsx";
          } else if (this.selectedReport == 2) {
            excelName = "Account_Recievable_Report.xlsx";
          } else {
            excelName = "InvoicesReports.xlsx";
          }

          //console.log(blob);
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = excelName;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
          // window.open(_url, "_blank").focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAllInvoice() {
      this.invoiceList = [];
      try {
        const response = await GetAllInvoice({
          search: this.search,
          limit: this.pagination.perPage,
          page: this.pagination.currentPage,
          selectedMovie: this.filters.selectedMovie,
          selectedVendor: this.filters.selectedVendor,
          is_paid:
            this.filters.invoiceStatus == "Full Paid"
              ? "1"
              : this.filters.invoiceStatus == "Partial Paid"
              ? "2"
              : this.filters.invoiceStatus == "Not Paid"
              ? "0"
              : this.filters.invoiceStatus == "strono"
              ? "3"
              : "",
          invNumbers: this.filters.invNumbers,
          invoiceFromDate: this.filters.invoiceFromDate,
          invoiceToDate: this.filters.invoiceToDate,
        });
        this.savePaginationAndFilters();
        if (response.data.status) {
          this.invoiceList = response.data.data.data;
          if (this.pagination.currentPage == 1) {
            this.pagination.totalRows = response.data.data.pagination.total;
          }
        }
      } catch (err) {
        console.log("Error in getting GetAllInvoice ", err);
      }
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async getVendors() {
      try {
        const response = await getAllVendor({});
        if (response.status == 200) {
          this.vendor_list = response.data.data.data;
        }
      } catch (err) {
        console.log("Error => ", err.message);
      }
    },

    onSendReminder(data) {
      this.sendReminderData.consolidateReminderVendor = null;
      this.sendReminderData.consolidateInvoiceNo = [];
      this.isBulkReminder = false;
      this.totalDue = 0;

      console.log(data.paidAmount);

      let currency = data.selectedCurrency ? data.selectedCurrency : "CHF";
      if (data.paidAmount) {
        this.totalDue =
          currency +
          " " +
          formatCurrency(
            parseFloat(data.invoice_total) -
              data.paidAmount.split(",").reduce(function (a, b) {
                return +parseFloat(a).toFixed(2) + +parseFloat(b).toFixed(2);
              })
          );
      } else {
        this.totalDue =
          currency + " " + formatCurrency(parseFloat(data.invoice_total));
      }

      if (
        data.reminderSentDate &&
        data.reminderSentDate.split(",").length == 1
      ) {
        this.sendReminderData.message =
          "<h3>2. Mahnung / Rappel / Reminder</h3><p>Gemäss unserer Buchhaltung sind die folgenden Rechnungen noch nicht beglichen.Sollten die Rechnungen bezahlt sein, bitten wir um Angabe des Zahlungsdatums.Andernfalls erwarten wir Ihre Zahlung oder eine Begründung für den ausstehenden Betrag in den nächsten 7 Tagen.</p><p>Selon notre comptabilité, les factures suivantes ne sont pas encore réglées.Nous vous prions de bien vouloir vous acquitter du montant dû dans un délai de 7 jours.</p><p>According to our accounting department, the following invoices have not yet been paid. If the invoices have been paid, please indicate the date of payment. Otherwise, we expect your payment or justification for the outstanding amount in the next 7 days.</p>";
      } else if (
        data.reminderSentDate &&
        data.reminderSentDate.split(",").length == 2
      ) {
        this.sendReminderData.message =
          "<h3>3. Mahnung / Rappel / Reminder</h3><p>Gemäss unserer Buchhaltung sind die folgenden Rechnungen noch nicht beglichen. Sollten die Rechnungen bezahlt sein, bitten wir um Angabe des Zahlungsdatums.Andernfalls erwarten wir Ihre Zahlung oder eine Begründung für den ausstehenden Betrag in den nächsten 3 Tagen.</p><p>Selon notre comptabilité les factures suivantes sont pas encore réglées. Nous vous prionsde bien vouloir vous acquitter dans les 3 jours du montant dû.</p><p>According to our accounting department, the following invoices have not yet been paid. If the invoices have been paid, please indicate the date of payment. Otherwise, we expect your payment or justification for the outstanding amount in the next 3 days.</p>";
      } else if (
        data.reminderSentDate &&
        data.reminderSentDate.split(",").length == 3
      ) {
        this.sendReminderData.message =
          "<h3>Mahnung / Rappel / Reminder</h3><p>Gemäss unserer Buchhaltung sind die folgenden Rechnungen noch nicht beglichen.Sollten die Rechnungen bezahlt sein, bitten wir um Angabe des Zahlungsdatums.Andernfalls erwarten wir Ihre Zahlung oder eine Begründung für den ausstehenden Betrag in den nächsten 3 Tagen.</p><p>Selon notre comptabilité les factures suivantes sont pas encore réglées. Nous vous prions de bien vouloir vous acquitter dans les 3 jours du montant dû.</p><p>According to our accounting department, the following invoices have not yet been paid. If the invoices have been paid, please indicate the date of payment. Otherwise, we expect your payment or justification for the outstanding amount in the next 3 days.</p>";
      } else if (!data.reminderSentDate) {
        this.sendReminderData.message =
          "<h3>1. Mahnung / Rappel / Reminder</h3><p>Gemäss unserer Buchhaltung sind die folgenden Rechnungen noch nicht beglichen. Sollten die Rechnungen bezahlt sein, bitten wir um Angabe des Zahlungsdatums. Andernfalls erwarten wir Ihre Zahlung oder eine Begründung für den ausstehenden Betrag in den nächsten 7 Tagen.</p><p>Selon notre comptabilité, les factures suivantes ne sont pas encore réglées.Nous vous prions de bien vouloir vous acquitter du montant dû dans un délai de 7 jours.</p><p>According to our accounting department, the following invoices have not yet been paid. If the invoices have been paid, please indicate the date of payment. Otherwise, we expect your payment or justification for the outstanding amount in the next 7 days.</p>";
      } else {
        this.sendReminderData.message = "";
      }

      this.selectedInvoice = data;
      this.showReminderModal = true;
    },
    async handleOk() {
      try {
        const response = await SendInvoiceReminder({
          sendReminderData: this.sendReminderData,
          selectedInvoice: this.selectedInvoice,
          totalDue: this.totalDue,
          isBulkReminder: this.isBulkReminder,
        });

        if (response.data.status) {
          this.loadAllInvoice();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in SendInvoiceReminder ", err);
      }
    },

    hideShowFilter() {
      this.filtersHidden = !this.filtersHidden;
    },
    exportData() {
      let payload = {
        selectedMovie: this.filters.selectedMovie,
        selectedVendor: this.filters.selectedVendor,
        is_paid:
          this.filters.invoiceStatus == "Full Paid"
            ? "1"
            : this.filters.invoiceStatus == "Partial Paid"
            ? "2"
            : this.filters.invoiceStatus == "Not Paid"
            ? "0"
            : this.filters.invoiceStatus == "strono"
            ? "3"
            : "",
        invNumbers: this.filters.invNumbers,
        invoiceFromDate: this.filters.invoiceFromDate,
        invoiceToDate: this.filters.invoiceToDate,
      };
      const queryString = qs.stringify(payload);
      let url = this.BASE_URL + `/revenue/exportAllInvoice?${queryString}`;
      fetch(url, {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          console.log(blob);
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
        });
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.strono_credit_canceled == 1) return "table-danger";
    },
    downloadReminder(file) {
      window.open(this.files_url + file, "__blank");
    },
    downloadBankInv(file) {
      window.open(this.files_url + file, "__blank");
    },

    async handleUploadBankInv() {
      this.loading = true;
      this.uploadMessage = null;
      try {
        const url = this.BASE_URL + "/revenue/uploadBankInvoice";
        const formData = new FormData();
        formData.append("file", this.bankInvoice);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: localStorage.getItem("access_token"),
            "x-access-token": localStorage.getItem("access_token"),
          },
        };
        //this.bankModalShow = false;
        const response = await axios.post(url, formData, config);
        if (response.data.message) {
          this.uploadMessage = response.data.message;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.bankInvoice = null;
        this.getAllInvoice();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in UploadRevenue ", err);
      }
    },

    onPageChange() {
      this.$nextTick(() => {
        this.getAllInvoice();
      });
    },

    savePaginationAndFilters() {
      localStorage.setItem(
        "revenueinvoice-FiltersAndPagination",
        JSON.stringify({
          filters: this.filters,
          pagination: this.pagination,
        })
      );
    },

    getLicensorUnpaidInvoice() {
      let getSelectedVendor = this.vendor_list.filter((x) => {
        return x.vendor_id == this.sendReminderData.consolidateReminderVendor;
      });
      if (getSelectedVendor.length) {
        this.selectedInvoice = getSelectedVendor[0];
      }

      this.onGetLicensorInvoiceNumbers();
    },
    async onGetLicensorInvoiceNumbers() {
      this.invoiceNumberList = [];
      try {
        const response = await GetLicensorInvoiceNumbers({
          licensorId: this.sendReminderData.consolidateReminderVendor,
        });

        if (response.data.status) {
          this.invoiceNumberList = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
