<template>
  <div>
    <b-alert variant="secondary" show>
      <b-row cols="12" class="mb-1 alert-heading">
        <b-col class="d-flex align-items-center justify-content-between">
          <Filters
            :showFilters="false"
            :hideRight="true"
            :hideLanguage="true"
            :hideStatus="true"
            @downloadCSV="downloadCSV"
            @submitFilter="filterSubmitted"
            @resetFilter="resetFilter"
          />
        </b-col>
      </b-row>
    </b-alert>
    <div>
      <b-row>
        <b-col
          class="d-flex align-items-end justify-content-end justify-content-sm-end"
        >
        </b-col>
      </b-row>
    </div>

    <b-card no-body>
      <b-table
        bordered
        v-if="$can('read', 'expense')"
        :fields="fields"
        :items="expenseList"
        empty-text="No matching records found"
        show-empty
        responsive
        small
        hover
      >
        <template #head(sr)="data">
          <b-badge v-if="pagination.total" variant="success">{{
            pagination.total
          }}</b-badge>
        </template>
        <template #cell(sr)="data">
          {{
            data.index + 1 + (pagination.currentPage - 1) * pagination.perPage
          }}
        </template>

        <template #cell(expense_type)="data">
          {{
            data.value == 1
              ? "MG PAID"
              : data.value == 2
              ? "BUMPER PAID"
              : data.value == 3
              ? "OVERAGES PAID"
              : data.value == 4
              ? "DIVERS PAID"
              : data.value == 5
              ? "P&A Expense"
              : data.value == 6
              ? "Less Licensor corridor Paid"
              : data.item.revenueType
          }}
        </template>
        <template #cell(expense_amount)="data">
          <b-form-input
            v-if="data.item.status == '100'"
            id="from"
            v-model="data.item.expense_amount"
            placeholder="Expense Amount"
            type="number"
          />
          <div v-else class="text-nowrap">
            <b>
              <!-- <span style="text-align: left">CHF</span> -->
              <span>{{
                data.value ? formatCurrency(data.value) : "0.00"
              }}</span></b
            >
          </div>
        </template>
        <template #cell(expense_date)="data">
          <flat-pickr
            v-if="data.item.status == '100'"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Revenue Date"
            class="form-control"
            v-model="data.item.expense_date"
          />

          <span v-else>{{
            data.value ? moment(data.value).format("DD/MM/YYYY") : ""
          }}</span>
        </template>

        <template #cell(action)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="$can('create', 'expense')"
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              class="mr-1"
              :variant="data.item.status == '100' ? 'success' : 'primary'"
              @click="
                enableEdit(
                  data.item,
                  data.item.status == '100' ? 'save' : 'edit'
                )
              "
            >
              {{ data.item.status == "100" ? "Save" : "Edit" }}
            </b-badge>
            <b-badge
              v-if="data.item.status == '100'"
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="info"
              @click="enableEdit(data.item, 'cancel')"
            >
              Cancel
            </b-badge>
            <b-badge
              v-if="$can('create', 'expense') && data.item.status != '100'"
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
              @click="showDeleteConfirm(data.item)"
            >
              Delete
            </b-badge>
          </div>
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.total"
                :per-page="pagination.perPage"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormInput,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { GetExpenseData, DeleteExpense } from "@/apiServices/RevenueServies";
import vSelect from "vue-select";
import moment from "moment";
import Filters from "../../../components/Filters.vue";
import { downloadFromURL } from "@/utils/helpers";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { formatCurrency } from "@/utils/helpers";
import { TokenService } from "@/apiServices/storageService";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BProgress,
    BBadge,
    vSelect,
    BButton,
    BTooltip,
    BPagination,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    Filters,
    ToastificationContentVue,
    flatPickr,
    BFormInput,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment: moment,
      pagination: {
        currentPage: 1,
        perPage: 25,
        totalRows: 1,
        total: 0,
        perPageOptions: [25, 50, 100],
      },
      fields: [
        { key: "sr", label: "SR", class: "text-center" },
        { key: "company", label: "Licensor", class: "text-left" },

        { key: "movie_unique", label: "Movie UniqueID", class: "text-right" },
        { key: "movie_title", label: "Movie", class: "text-left" },
        // { key: "territory_name", label: "Territory" },
        { key: "expense_type", label: "Expense Type", class: "text-left" },
        { key: "territoryParentName", label: "Territory", class: "text-left" },
        { key: "expense_date", label: "Date", class: "text-center" },
        {
          key: "expense_amount",
          label: "Amount(CHF)",
          class: "text-right",
          variant: "danger",
        },
        { key: "action", class: "text-center", label: "Action" },
      ],

      filters: {},

      expenseList: [],
    };
  },

  beforeMount() {
    this.getAllExpense();
  },
  methods: {
    formatCurrency,
    downloadCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }
        let payload = {
          search: this.search,
          limit: this.pagination.perPage,
          page: this.pagination.currentPage,
        };

        if (this.filters) {
          payload = {
            ...payload,
            movie_id: this.filters.movie ? this.filters.movie.movie_id : "",
            vendor_id: this.filters.vendor ? this.filters.vendor.vendor_id : "",
            territory_id: this.filters.territory
              ? this.filters.territory.lbl_id
              : "",
            start_date: this.filters.date_range
              ? this.filters.date_range.split(" to ")[0]
              : "",
            end_date: this.filters.date_range
              ? this.filters.date_range.split(" to ")[1]
              : "",
          };
        }

        let baseURL = `${process.env.VUE_APP_BASEURL}/revenue/sheet/download-expenses?authorization=${token}`;

        const urlStr = this.getURLString(baseURL, payload);

        downloadFromURL(urlStr, "Expenses", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },
    getURLString(baseURL, payload) {
      let urlKeyValuePairs = [];

      for (let key in payload) {
        urlKeyValuePairs.push(`${key}=${payload[key]}`);
      }

      return baseURL + "&" + urlKeyValuePairs.join("&");
    },
    async getAllExpense() {
      try {
        let payload = {
          search: this.search,
          limit: this.pagination.perPage,
          page: this.pagination.currentPage,
        };
        if (this.filters) {
          console.log(this.filters, "fil");
          //return;
          payload = {
            ...payload,
            movie_id: this.filters.movie ? this.filters.movie : "",
            vendor_id: this.filters.vendor ? this.filters.vendor.vendor_id : "",
            territory_id: this.filters.territory
              ? this.filters.territory.lbl_id
              : "",
            start_date: this.filters.date_range
              ? this.filters.date_range.split(" to ")[0]
              : "",
            end_date: this.filters.date_range
              ? this.filters.date_range.split(" to ")[1]
              : "",
          };
        }
        const response = await GetExpenseData(payload);
        if (response.data.status) {
          this.expenseList = response.data.data.data;
          if (this.pagination.currentPage == 1) {
            this.pagination.total = response.data.data.pagination.total;
          }
        }
      } catch (err) {
        console.log("Error in getting expense list ", err);
      }
    },
    async deleteExpense(data, type) {
      const response = await DeleteExpense({
        data: data,
        type: type,
      });
      if (response.data.status) {
        this.getAllExpense();
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      return this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Delete Failed",
          icon: "EditIcon",
          variant: "danger",
        },
      });
    },

    showDeleteConfirm(data) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to delete?", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) this.deleteExpense(data, 0);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    enableEdit(data, type) {
      if (type == "save") {
        this.deleteExpense(data, 1);
      } else if (type == "cancel") {
        let getIndex = this.expenseList.findIndex(
          (x) => x.revenue_expense_id == data.revenue_expense_id
        );
        if (getIndex >= 0) {
          this.expenseList[getIndex].status = 1;
        }
      } else {
        let getIndex = this.expenseList.findIndex(
          (x) => x.revenue_expense_id == data.revenue_expense_id
        );
        if (getIndex >= 0) {
          this.expenseList[getIndex].status = 100;
        }
      }
    },

    filterSubmitted(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.onChange();
    },
    resetFilter() {
      this.filters = {};
      this.pagination.currentPage = 1;
      this.onChange();
    },
    onChange() {
      this.$nextTick(() => {
        this.getAllExpense();
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
