<template>
  <div class="mt-2">
    <b-badge variant="info">Total Bank Payments : {{ this.totalRows }}</b-badge>
    <b-button
      v-b-tooltip.hover
      title="refresh after file upload"
      class="float-left"
      size="sm"
      variant="info"
      @click="getPayments"
      >Refresh</b-button
    >
    <b-card no-body>
      <b-table
        bordered
        ref="selectableTable"
        :fields="fields"
        :items="bankPayDataList"
        responsive
        empty-text="No  records found"
        show-empty
        small
        hover
        :tbody-tr-class="rowClass"
      >
        <template #cell(sr)="data">
          <div class="text-nowrap">
            {{ data.index + 1 + (currentPage - 1) * limit }}
          </div>
        </template>
        <template #cell(paidAmountDate)="data">
          <div class="text-nowrap">
            {{ moment(data.item.paidAmountDate).format("DD/MM/YYYY") }}
          </div>
        </template>
        <template #cell(invoiceUploadedDate)="data">
          <div class="text-nowrap">
            {{ moment(data.item.invoiceUploadedDate).format("DD/MM/YYYY") }}
          </div>
        </template>
        <!-- <template #cell(action)="data">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="LinkPayment(data.item)"
              >Link with Invoice</b-button
            >
          </div>
        </template> -->
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                @change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BTabs,
  BTab,
  VBTooltip,
  BPagination,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  GetAllBankPayments,
  DeleteRevenueById,
} from "@/apiServices/RevenueServies";
import vSelect from "vue-select";
import moment from "moment";
import { getAllData } from "@/apiServices/MastersServices";
import { getAllVendor } from "@/apiServices/VendorServices";
import { getAllMovie } from "@/apiServices/MovieServices";
import Ripple from "vue-ripple-directive";
import { TokenService } from "@/apiServices/storageService";

import flatPickr from "vue-flatpickr-component";
import { downloadFromURL } from "@/utils/helpers";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { formatCurrency } from "@/utils/helpers";

export default {
  props: {
    isPaymentPage: {
      type: String,
    },
    invNumber: {
      type: Number,
    },
  },
  components: {
    BCard,
    BTable,
    BProgress,
    BBadge,
    vSelect,
    BButton,
    BTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    flatPickr,
    BTabs,
    BTab,
    BFormRadio,
    VBTooltip,
    BPagination,
    ToastificationContentVue,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      stickyHeader: true,
      selectAllStatus: "0",
      filterTempTerritory: null,
      optionTempTerritory: [],
      sub_rights_list: [],
      subRights: [],
      filterTempRights: null,
      optionTempRights: [],
      filterTempMovie: null,
      optionTempMovie: [],
      revenueDateRange: null,
      selectedCustomer: null,
      selectedRight: null,
      optionCustomer: [],
      relevanceOptions: [
        { title: "All", value: null },
        { title: "Relevant For Royalty Report", value: 1 },
        { title: "Not Relevant For Royalty Report", value: 0 },
      ],
      optionRights: [
        { title: "Theatrical Rights", value: 1 },
        { title: "Other Rights", value: 2 },
      ],

      selectedStatus: null,
      selectedRelevance: 1,
      setIsInvoice: false,
      optionStatus: [
        { title: "Billed", value: 1 },
        { title: "Unbilled", value: 0 },
        // { title: "Generate Manual Invoice", value: 3 },
      ],
      moment: moment,
      currentPage: 1,
      totalPages: 0,
      limit: 25,
      totalRows: null,
      perPageOptions: [25, 50, 100],
      fields: [
        { key: "sr", label: "SR" },

        { key: "inv_unique_number", label: "Invoice No. " },
        { key: "paidAmount", label: "Amount Paid" },

        { key: "paidAmountDate", label: "Paid Amount Date" },
        { key: "invoice_reference_number", label: "Invoice Refrence Number" },
        { key: "invoiceUploadedDate", label: "File Upload Date" },
        // {
        //   key: "action",
        //   label: "Actions",
        // },
      ],

      bankPayDataList: [],
      bankPayDataListAll: [],
      selectedRevenueItems: [],
      showGenerateInvoice: false,
      filtersHidden: true,
      selectedPage: "additional",
      optionsPage: [
        { text: "Add Revenue List", value: "revenue" },
        { text: "Additional Cost List", value: "additional" },
      ],
    };
  },

  watch: {
    selectedRevenueItems() {
      if (this.selectedRevenueItems.length > 0) {
        if (this.selectedRevenueItems.length != this.bankPayDataList.length) {
          this.selectAllStatus = "0";
        } else {
          this.selectAllStatus = "1";
        }
      } else {
      }
    },
  },
  async beforeMount() {
    this.getPayments();
  },
  methods: {
    LinkPayment(data) {},
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.paymentLinked || item.paymentLinked == 0) return "table-danger";
    },
    onInputAdd() {
      this.$router.push("/addrevenueMain?tabIndex=1");
    },
    formatCurrency,

    downloadExport(type) {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }
        let payload = {
          vendor_id: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : "",
        };

        let baseURL = `${process.env.VUE_APP_BASEURL}/revenue/sheet/download-additional-revenue?authorization=${token}`;

        const urlStr = this.getURLString(baseURL, payload);

        console.log({ urlStr });

        downloadFromURL(urlStr, "Revenue", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },
    getURLString(baseURL, payload) {
      let urlKeyValuePairs = [];

      for (let key in payload) {
        urlKeyValuePairs.push(`${key}=${payload[key]}`);
      }

      return baseURL + "&" + urlKeyValuePairs.join("&");
    },
    async getPayments() {
      this.selectedRevenueItems = [];
      this.bankPayDataList = [];
      this.bankPayDataListAll = [];
      this.optionTempTerritory = [];
      this.optionTempRights = [];

      try {
        const response = await GetAllBankPayments({
          search: this.search,
          limit: this.limit,
          page: this.currentPage,
        });
        if (response.data.status) {
          this.bankPayDataListAll = response.data.data.data;
          this.bankPayDataList = response.data.data.data;
          if (
            response.data.data.pagination &&
            response.data.data.pagination.total
          ) {
            this.totalRows = response.data.data.pagination.total;
          }
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllVendor() {
      try {
        const response = await getAllVendor({});
        if (response.data) {
          this.optionCustomer = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async GetAllMovie() {
      this.optionTempMovie = [];
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionTempMovie = response.data.data.data;
          console.log(this.optionTempMovie, "dad");
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async deleteRevenueById(data, id, type) {
      const response = await DeleteRevenueById({
        additional_cost_id: id,
        type: type,
        data: data,
      });
      if (response.data.status) {
        this.getAllAdditionalRevenueData();
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      return this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Delete Failed",
          icon: "EditIcon",
          variant: "danger",
        },
      });
    },
    showDeleteConfirm(data, type) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to Update?", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true)
            this.deleteRevenueById(data, data.additional_cost_id, type);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    hideShowFilter() {
      this.filtersHidden = !this.filtersHidden;
    },
    onPageChange() {
      this.$nextTick(() => {
        this.getAllAdditionalRevenueData();
      });
    },
    async onSearch() {
      this.setIsInvoice = false;
      this.getAllAdditionalRevenueData();
    },
    async resetForm() {
      this.selectedCustomer = null;
      this.selectedRight = null;
      this.selectedStatus = null;
      this.revenueDateRange = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
