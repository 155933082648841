<template>
  <div>
    <b-alert variant="secondary" show>
      <b-row cols="12" class="mb-1 alert-heading">
        <b-col class="d-flex align-items-center justify-content-between">
          <b-dropdown
            left
            size="sm"
            text="Actions"
            variant="gradient-secondary"
            id="dropdown-form"
            ref="dropdown"
          >
            <b-dropdown-form>
              <b-button
                block
                size="sm"
                class="w-100"
                variant="gradient-success"
                @click="downloadSample('0', 'Theatrical_Revenues')"
              >
                <span class="text-nowrap">
                  Download Theatrical Revenues Sample.xlsx</span
                >
              </b-button>
              <b-dropdown-divider></b-dropdown-divider>

              <b-button
                block
                size="sm"
                class="w-100"
                variant="gradient-success"
                @click="downloadSample('1', 'Other_Revenues')"
              >
                <span class="text-nowrap">
                  Download Other Revenues Sample.xlsx</span
                >
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-alert>

    <b-alert dismissible variant="danger" show v-if="showErrorData == true">
      <h4 class="alert-heading">Error in upload ...!</h4>
      <div class="alert-body">
        <div v-if="errorExcelData && errorExcelData.excelDataErrors">
          <span>Issues Found in uploaded File. </span>
          <span
            >Please click on download to get file with errors
            <b-badge
              @click="downloadErrorExcel"
              variant="danger"
              class="cursor-pointer"
            >
              Download </b-badge
            ><br
          /></span>
          <span>Upload the downloaded file again after error fixes.</span>
        </div>

        <div v-else>
          <span>{{ errorExcelData.messageError }}</span>
        </div>
      </div>
    </b-alert>
    <b-alert dismissible variant="success" show v-if="showSuccess == true">
      <h4 class="alert-heading">Revenue Added Successfully...!</h4>
      <div class="alert-body">
        <span
          ><b>{{ insertedDataCount }} </b> revenue items added successfully.
        </span>
      </div>
    </b-alert>

    <b-form class="m-1" @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group label="Licensee">
            <v-select
              v-model="selectedCustomer"
              placeholder="Licensee"
              label="company"
              :options="optionCustomer"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Revenue Type">
            <v-select
              v-model="selectedRight"
              placeholder="Revenue Type"
              label="title"
              :options="optionRights"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Revenue Date">
            <flat-pickr
              v-model="revenueDate"
              placeholder="Revenue Date"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Revenue Excel">
            <b-form-file v-model="revenueFile" id="IANA" accept=".xlsx" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-checkbox v-model="isHistoryData"
            ><b>check this if revenue is history data?</b> (if this is checked
            revenue date will be considered as royalty report date and invoicing
            is not applicable for this revenue).
          </b-form-checkbox></b-col
        >

        <b-col v-if="isHistoryData" class="mt-1 mb-1" md="3">
          <b-form-group label="Royalty Report Date">
            <flat-pickr
              v-model="royaltyReportDate"
              placeholder="Royalty Report Date"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-checkbox v-model="ischeckRoyaltyReport"
            ><b>check for sent royalty reports?</b> (if this is checked revenue
            date should be greater then sent royalty report date statement
            period).
          </b-form-checkbox></b-col
        >
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-2 d-flex justify-content-center">
          <b-button
            :disabled="
              (isHistoryData && !royaltyReportDate) ||
              !selectedCustomer ||
              !selectedRight ||
              !revenueDate ||
              !revenueFile ||
              loading
                ? true
                : false
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 mb-1 md-mb-0"
            @click="uploadRevenueData"
          >
            <b-spinner v-if="loading" small /> Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="resetForm"
            class="mb-1 md-mb-0"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCardGroup,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BBadge,
  BSpinner,
  BTabs,
  BTab,
  BDropdownDivider,
  BDropdownForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import * as XLSX from "xlsx";
import { getAllVendor } from "@/apiServices/VendorServices";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardGroup,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    vSelect,
    flatPickr,
    XLSX,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    BDropdownForm,

    BTabs,
    BTab,
    BDropdownDivider,
    ToastificationContentVue,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSuccess: false,
      insertedDataCount: null,
      showErrorData: false,
      errorExcelData: null,
      BASE_URL: process.env.VUE_APP_BASEURL,
      revenueDate: null,
      revenueFile: null,
      isHistoryData: false,
      ischeckRoyaltyReport: true,
      selectedCustomer: null,
      loading: false,

      optionCustomer: [],
      selectedRight: null,
      optionRights: [
        { title: "Theatrical", value: 1 },
        { title: "Other Rights", value: 2 },
      ],
    };
  },
  beforeMount() {
    this.GetAllVendor();
  },

  methods: {
    downloadSample(id, fileName) {
      let url = null;
      const anchorElement = document.createElement("a");
      document.body.appendChild(anchorElement);
      anchorElement.style.display = "none";
      if (id == "0") {
        url = this.BASE_URL + "/revenue/revenuesampledownload?type=1";
      } else if (id == "1") {
        url = this.BASE_URL + "/revenue/revenuesampledownload?type=2";
      }
      fetch(url, {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          //console.log(blob);
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = fileName;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
          // window.open(_url, "_blank").focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async uploadRevenueData() {
      this.loading = true;
      this.showSuccess = false;
      this.showErrorData = false;
      try {
        const url = this.BASE_URL + "/revenue/uploadRevenueData";
        const formData = new FormData();
        formData.append("file", this.revenueFile);
        formData.append("upload_type", this.selectedRight.value);
        formData.append("vendor_id", this.selectedCustomer.vendor_id);
        formData.append("revenue_date", this.revenueDate);
        formData.append("royaltyReportDate", this.royaltyReportDate);
        formData.append("is_history_data", this.isHistoryData == true ? 1 : 0);
        formData.append(
          "ischeckRoyaltyReport",
          this.ischeckRoyaltyReport == true ? 1 : 0
        );
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: localStorage.getItem("access_token"),
            "x-access-token": localStorage.getItem("access_token"),
          },
        };

        const response = await axios.post(url, formData, config);

        if (response.data.status) {
          this.insertedDataCount = response.data.data.insertedDataCount;
          this.showSuccess = true;
          this.resetForm();
          //
        } else {
          if (response.data.status == false) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Something went wrong.!",
                icon: "EditIcon",
                variant: "danger",
              },
            });

            this.errorExcelData = response.data.data;
            this.showErrorData = true;
          }
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in UploadRevenue ", err);
      }
    },

    downloadErrorExcel() {
      if (this.errorExcelData && this.errorExcelData.excelDataErrors) {
        let data = [];

        let sheetName = "";
        let excelName = "";

        for (let i = 0; i < this.errorExcelData.excelDataErrors.length; i++) {
          var obj = {};
          let currentData = this.errorExcelData.excelDataErrors[i];

          console.log(currentData, "currentData");
          if (this.selectedRight.value == "1") {
            obj.MOVIE_UNIQUE_ID = currentData.uniqueId;
            obj.TITLE = currentData.title;
            obj.SUB_RIGHT = currentData.rightIdentifier;
            obj.TERRITORY = currentData.territory;
            obj.ADMISSION = currentData.addmission;
            obj.BO_NET = currentData.boNet;
            obj.RENTAL_NET = currentData.rentalNet;
            obj.ERRORS = currentData.errorArray;
          } else if (this.selectedRight.value == "2") {
            obj.MOVIE_UNIQUE_ID = currentData.no_per_client;
            obj.TITLE = currentData.title;
            obj.SUB_RIGHT = currentData.rightIdentifier;
            obj.TERRITORY = currentData.territory;
            obj.UNITS_SOLD = currentData.unit_sold;
            obj.SHARE_NET = currentData.share_net;
            obj.ERRORS = currentData.errorArray;
          }
          data.push(obj);
        }

        if (this.selectedRight.value == "1") {
          sheetName = "RevenueT";
          excelName = "TheatricalRevenueErrors.xlsx";
        } else if (this.selectedRight.value == "2") {
          sheetName = "RevenueNT";
          excelName = "OthersRevenueErrors.xlsx";
        } else {
        }

        var dataFinal = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataFinal, sheetName); // sheetAName is name of Worksheet
        XLSX.writeFile(wb, excelName);
      }
    },

    resetForm() {
      this.selectedCustomer = null;
      this.selectedRight = null;
      this.insertedDataCount = null;
      this.showErrorData = false;
      this.errorExcelData = null;
      this.BASE_URL = process.env.VUE_APP_BASEURL;
      this.revenueDate = null;
      this.royaltyReportDate = null;
      this.revenueFile = null;
      //this.optionCustomer = [];
    },
    async GetAllVendor() {
      try {
        const response = await getAllVendor({});
        if (response.data) {
          this.optionCustomer = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
